// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SETTINGS = '/settings';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  confirm: path(ROOTS_AUTH, '/confirm'),
  // register: path(ROOTS_AUTH, '/register'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  // verify: path(ROOTS_AUTH, '/verify'),
  // resetPassword: path(ROOTS_AUTH, '/reset-password'),
  // newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  actionitems: path(ROOTS_DASHBOARD, '/actionitems'),
  proposaldetails: path(ROOTS_DASHBOARD, '/proposaldetails/:proposalId'),
  knowledgebase: path(ROOTS_DASHBOARD, '/knowledgebase'),
  adminview: path(ROOTS_DASHBOARD, '/adminview'),
  files: path(ROOTS_DASHBOARD, '/files'),
};

export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  account: path(ROOTS_SETTINGS, '/account'),
  billing: path(ROOTS_SETTINGS, '/billing'),
  // user: {
  //   root: path(ROOTS_DASHBOARD, '/user'),
  //   five: path(ROOTS_DASHBOARD, '/user/five'),
  //   six: path(ROOTS_DASHBOARD, '/user/six'),
  // },
};
