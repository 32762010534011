import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  LoginPage,
  ConfirmPage,
  Page404,
  YourActionItems,
  ProposalDetails,
  KnowledgeBase,
  NOFOAdminView,
  AccountPage,
  BillingPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // root
    { path: '/', element: <Navigate to="/auth/login" replace /> },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'confirm',
          element: (
            <GuestGuard>
              <ConfirmPage />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <RegisterPage />
        //     </GuestGuard>
        //   ),
        // },
        // { path: 'login-unprotected', element: <LoginPage /> },
        // { path: 'register-unprotected', element: <RegisterPage /> },
        // {
        //   element: <CompactLayout />,
        //   children: [
        //     { path: 'reset-password', element: <ResetPasswordPage /> },
        //     { path: 'new-password', element: <NewPasswordPage /> },
        //     { path: 'verify', element: <VerifyCodePage /> },
        //   ],
        // },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'proposaldetails/:proposalId', element: <ProposalDetails /> },
        { path: 'knowledgebase', element: <KnowledgeBase /> },
        { path: 'adminview', element: <NOFOAdminView /> },
        { path: 'actionitems', element: <YourActionItems /> },
      ],
    },
    {
      path: '/settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'account', element: <AccountPage /> },
        { path: 'billing', element: <BillingPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
