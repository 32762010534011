import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';

// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  Tabs,
  Tab,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const FETCH_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [totalUnRead, setTotalUnRead] = useState(0);
  const [selectedTab, setSelectedTab] = useState('all');

  useEffect(() => {
    fetchNotifications();

    const intervalId = setInterval(fetchNotifications, FETCH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchNotifications = async () => {
    try {
      const { currentUser } = getAuth();
      const authToken = await currentUser.getIdToken();
      const userEmail = currentUser.email;

      const response = await fetch(
        `/api/notifications?userEmail=${encodeURIComponent(userEmail)}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Sort the notifications by date (most recent first)
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setNotifications(sortedData);
        setTotalUnRead(sortedData.filter((item) => item.isUnRead).length);
      } else {
        console.error('Error fetching notifications:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = async () => {
    setOpenPopover(null);
    await markNotificationsAsRead();
  };

  const handleNotificationClick = () => {
    markNotificationsAsRead();
  };

  const markNotificationsAsRead = async () => {
    try {
      const { currentUser } = getAuth();
      const authToken = await currentUser.getIdToken();
      const notificationIds = notifications.map((notification) => notification._id);

      const response = await fetch('/api/readnotifications', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ notificationIds }),
      });

      if (response.ok) {
        setNotifications(
          notifications.map((notification) => ({
            ...notification,
            isUnRead: false,
          }))
        );
        setTotalUnRead(0);
      } else {
        console.error('Error marking notifications as read:', response.statusText);
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const filteredNotifications = notifications.filter((notification) => {
    if (selectedTab === 'all') return true;
    return notification.type === selectedTab;
  });

  return (
    <>
      <Tooltip title="Notifications">
        <IconButtonAnimate
          color={openPopover ? 'primary' : 'default'}
          onClick={handleOpenPopover}
          sx={{ width: 40, height: 40 }}
        >
          <Badge badgeContent={totalUnRead} color="error">
            <Iconify icon="eva:bell-fill" />
          </Badge>
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 450, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="All" value="all" />
            <Tab label="Critical" value="critical" />
            <Tab label="Action Required" value="action" />
            <Tab label="Review" value="review" />
          </Tabs>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ maxHeight: 'calc(100vh - 200px)' }}>
          <List disablePadding>
            {filteredNotifications.map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={{
                  ...notification,
                  createdAt: new Date(notification.createdAt),
                }}
                onNotificationClick={handleNotificationClick}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    avatar: PropTypes.node,
    redirect: PropTypes.string,
    type: PropTypes.string,
    subtype: PropTypes.string,
    title: PropTypes.string,
    isUnRead: PropTypes.bool,
    description: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date),
  }),
  onNotificationClick: PropTypes.func,
};

function NotificationItem({ notification, onNotificationClick }) {
  const { avatar, title } = renderContent(notification);

  const handleNotificationClick = () => {
    if (notification.redirect) {
      window.location.href = notification.redirect;
    }
    if (onNotificationClick) {
      onNotificationClick();
    }
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={handleNotificationClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{fToNow(notification.createdAt)}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.subtype === 'custom_meeting_alert') {
    return {
      avatar: <AnnouncementIcon color="error" width={24} height={24} />,
      title,
    };
  }
  if (notification.subtype === 'action_item_assigned') {
    return {
      avatar: <Iconify icon="eva:checkmark-circle-2-fill" color="warning" width={24} height={24} />,
      title,
    };
  }
  if (notification.subtype === 'meeting_no_agenda') {
    return {
      avatar: <Iconify icon="ic:twotone-event-busy" color="warning" width={24} height={24} />,
      title,
    };
  }
  if (notification.subtype === 'meeting_summary_available') {
    return {
      avatar: <Iconify icon="eva:file-text-fill" color="info" width={24} height={24} />,
      title,
    };
  }
  if (notification.subtype === 'project_summary_available') {
    return {
      avatar: <Iconify icon="eva:clipboard-fill" color="success" width={24} height={24} />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
