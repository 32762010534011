import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Iconify = forwardRef(({ icon, width = 20, color, sx, ...other }, ref) => (
  <Box
    ref={ref}
    component={Icon}
    icon={icon}
    sx={{
      width,
      height: width,
      color: (theme) => {
        if (color === 'primary') return theme.palette.primary.main;
        if (color === 'secondary') return theme.palette.secondary.main;
        if (color === 'info') return theme.palette.info.main;
        if (color === 'success') return theme.palette.success.main;
        if (color === 'warning') return theme.palette.warning.main;
        if (color === 'error') return theme.palette.error.main;
        return 'inherit';
      },
      ...sx,
    }}
    {...other}
  />
));

Iconify.propTypes = {
  sx: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  color: PropTypes.string,
};

export default Iconify;
