import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// config
import { FIREBASE_API } from '../config-global';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  mfaEnabled: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      mfaEnabled: action.payload.mfaEnabled,
    };
  }

  return state;
};

export const AuthContext = createContext(null);

const firebaseApp = initializeApp(FIREBASE_API);
const AUTH = getAuth(firebaseApp);
const DB = getFirestore(firebaseApp);

// Email sign-in configuration
const actionCodeSettings = {
  url: `${window.location.origin}/auth/confirm`,
  handleCodeInApp: true,
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          try {
            const userRef = doc(DB, 'users', user.uid);
            const docSnap = await getDoc(userRef);
            const profile = docSnap.data();

            // Check if userOrg exists, if not, create it
            if (profile && !profile.userOrg && user.email) {
              const emailParts = user.email.split('@');
              const domain = emailParts[1];
              const userOrg = domain.replace(/\./g, '_');

              // Update the user document with userOrg
              await setDoc(userRef, {
                ...profile,
                userOrg,
              });

              // Update profile with new userOrg
              profile.userOrg = userOrg;
            }

            dispatch({
              type: 'INITIAL',
              payload: {
                isAuthenticated: true,
                user: {
                  ...user,
                  ...(profile || {}),
                  role: 'admin',
                },
                mfaEnabled: multiFactor(user).enrolledFactors.length > 0,
              },
            });
          } catch (error) {
            console.error('Firestore error:', error);
            dispatch({
              type: 'INITIAL',
              payload: {
                isAuthenticated: true,
                user: {
                  ...user,
                  role: 'admin',
                },
                mfaEnabled: false,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
              mfaEnabled: false,
            },
          });
        }
      });
    } catch (error) {
      console.error('Auth error:', error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Send sign-in email link
  const sendSignInLink = useCallback(async (email) => {
    try {
      await sendSignInLinkToEmail(AUTH, email, actionCodeSettings);
      // Save the email for later use
      window.localStorage.setItem('emailForSignIn', email);
    } catch (error) {
      console.error('Error sending sign-in link:', error);
      throw error;
    }
  }, []);

  const completeSignInWithEmail = useCallback(async () => {
    try {
      if (isSignInWithEmailLink(AUTH, window.location.href)) {
        const email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          throw new Error('Email not found in localStorage');
        }
        const result = await signInWithEmailLink(AUTH, email, window.location.href);

        // Extract organization from email
        const emailParts = email.split('@');
        const domain = emailParts[1];
        const userOrg = domain.replace(/\./g, '_'); // Replace all dots with underscores

        // Create user document with userOrg field
        const userDocRef = doc(DB, 'users', result.user.uid);
        await setDoc(userDocRef, {
          email: result.user.email,
          userOrg,
          createdAt: new Date(),
        });

        window.localStorage.removeItem('emailForSignIn');
        return result;
      }
      return null;
    } catch (error) {
      console.error('Error completing sign-in:', error);
      throw error;
    }
  }, []);

  // Enroll MFA
  const enrollMFA = useCallback(async (phoneNumber, verificationCode) => {
    try {
      const user = AUTH.currentUser;
      const multiFactorSession = await multiFactor(user).getSession();

      const phoneAuthProvider = new PhoneAuthProvider(AUTH);
      const phoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession,
      };

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, null);

      // Complete enrollment with verification code
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(user).enroll(multiFactorAssertion, 'Phone Number');

      return true;
    } catch (error) {
      console.error('Error enrolling MFA:', error);
      throw error;
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    signOut(AUTH);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      mfaEnabled: state.mfaEnabled,
      method: 'firebase',
      sendSignInLink,
      completeSignInWithEmail,
      enrollMFA,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.mfaEnabled,
      sendSignInLink,
      completeSignInWithEmail,
      enrollMFA,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
