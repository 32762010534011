// EmailAuth.js
import { useState } from 'react';
import { Button, TextField, Box, Alert, CircularProgress } from '@mui/material';
import { useAuthContext } from '../../auth/useAuthContext';

export default function EmailAuth() {
  const [email, setEmail] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { sendSignInLink } = useAuthContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await sendSignInLink(email);
      setLinkSent(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 400,
        width: '100%',
        mx: 'auto',
      }}
    >
      {error && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      )}

      {linkSent ? (
        <Alert severity="success">
          Check your email for the sign-in link! The link will expire in 24 hours.
        </Alert>
      ) : (
        <>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isLoading}
          />

          <Button
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            disabled={isLoading}
            sx={{
              height: '48px',
              textTransform: 'none',
              fontWeight: 600,
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Continue with Email'}
          </Button>
        </>
      )}
    </Box>
  );
}
