import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const ConfirmPage = Loadable(lazy(() => import('../pages/auth/ConfirmPage')));

export const YourActionItems = Loadable(lazy(() => import('../pages/dashboard/YourActionItems')));
export const KnowledgeBase = Loadable(lazy(() => import('../pages/dashboard/KnowledgeBase')));
export const NOFOAdminView = Loadable(lazy(() => import('../pages/dashboard/NOFOAdminView')));
export const ProposalDetails = Loadable(lazy(() => import('../pages/dashboard/ProposalDetails')));
export const AccountPage = Loadable(lazy(() => import('../pages/user/Account')));
export const BillingPage = Loadable(lazy(() => import('../pages/user/Billing')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
