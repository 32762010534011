// @mui
import { Tooltip, Stack, Typography, Box } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// layouts
import LoginLayout from '../../layouts/login';
//
import EmailAuth from './EmailAuth';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Sign in or register with Avila
        </Typography>
        <Typography
          component="div"
          sx={{ color: 'text.secondary', mt: 3, typography: 'caption', textAlign: 'center' }}
        >
          {'By signing up, I agree to '}
          <a
            href="https://www.getavila.ai/utility-pages/terms-conditions#terms-and-conditions"
            style={{ textDecoration: 'underline', color: 'var(--text-primary)' }}
          >
            Terms of Service
          </a>
          {' and '}
          <a
            href="https://www.getavila.ai/utility-pages/terms-conditions#privacy-policy"
            style={{ textDecoration: 'underline', color: 'var(--text-primary)' }}
          >
            Privacy Policy
          </a>
          .
        </Typography>
      </Stack>

      <EmailAuth />
    </LoginLayout>
  );
}
