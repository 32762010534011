import Iconify from '../../../components/iconify';

// routes
import { PATH_DASHBOARD, PATH_SETTINGS } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// Define new Iconify icons
const ICONS = {
  meetings: <Iconify icon="solar:calendar-bold-duotone" width={24} />,
  user: <Iconify icon="solar:user-bold-duotone" width={24} />,
  proposals: <Iconify icon="solar:document-bold-duotone" width={24} />,
  knowledgebase: <Iconify icon="solar:book-bold-duotone" width={24} />, // New Icon for Knowledge Base
  actionItems: <Iconify icon="ic:twotone-fact-check" width={24} />,
  billing: <Iconify icon="solar:bill-list-bold" width={24} />,
  settings: <Iconify icon="solar:settings-bold-duotone" width={24} />,
};

const navConfig = [
  // Proposals
  {
    subheader: 'Proposals',
    items: [
      {
        title: 'Proposals',
        path: PATH_DASHBOARD.proposaldetails,
        icon: ICONS.proposals,
      },
      {
        title: 'Knowledge Base',
        path: PATH_DASHBOARD.knowledgebase,
        icon: ICONS.knowledgebase,
      },
    ],
  },
  // Personal
  {
    subheader: 'Personal',
    items: [
      {
        title: 'Action Items',
        path: PATH_DASHBOARD.actionitems,
        icon: ICONS.actionItems,
      },
    ],
  },
  // Settings
  {
    subheader: 'Settings',
    items: [
      {
        title: 'User Settings',
        path: PATH_SETTINGS.account,
        icon: ICONS.settings,
      },
      {
        title: 'Billing',
        path: PATH_SETTINGS.billing,
        icon: ICONS.billing,
      },
    ],
  },
];

export default navConfig;
